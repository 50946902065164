import styled from "@emotion/styled";
import { css } from "@emotion/react";

import check from "assets/svg/icon-footer-check.svg";

type Props = {
  isShow: boolean;
};

export const Wrapper = styled.form`
  width: 500px;

  @media (max-width: 1112px) {
    width: 100%;
  }
`;

export const Dropdown = styled.div<Props>`
  display: none;

  @media (max-width: 760px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 62px;
    background: #0f2c4f;

    span {
      margin-right: 12px;
      font-size: 15px;
      line-height: 15px;
      color: var(--ierp-color-white);
    }

    img {
      transform: ${({ isShow }) => (isShow ? "rotate(-180deg)" : "rotate(0)")};
      transition: transform 250ms linear;
    }
  }

  @media (max-width: 374px) {
    height: 50px;

    span {
      font-size: 13px;
    }
  }
`;

export const InputBox = styled.div<Props>`
  @media (max-width: 760px) {
    display: flex;
    flex-direction: column;
    padding: 0 15px;

    ${({ isShow }) =>
      isShow
        ? css`
            opacity: 1;
            height: auto;
          `
        : css`
            opacity: 0;
            height: 0;

            .titleBox {
              display: none;
            }

            ul {
              display: none;
            }

            .submit {
              display: none;
            }
          `}

    button {
      order: 3;
    }
  }
`;

export const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  margin-bottom: 15px;

  @media (max-width: 760px) {
    order: 2;
    margin: 0 auto 30px;
  }

  @media (max-width: 374px) {
    margin-bottom: 20px;
  }
`;

export const Title = styled.strong`
  font-size: 16px;

  @media (max-width: 760px) {
    display: none;
  }
`;

export const CheckBox = styled.div`
  display: flex;

  button {
    font-size: 14px;
    line-height: 30px;
    color: var(--ierp-text-secondary01);
  }
`;

export const Label = styled.label`
  input[type="checkbox"] {
    cursor: pointer;
    margin-right: 12px;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    border: 1px solid #eee;
    appearance: none;
    -webkit-appearance: none;
  }

  input[type="checkbox"]:checked {
    background: var(--ierp-color-primary) url(${check}) no-repeat center center;
    border: none;
  }

  @media (max-width: 374px) {
    input[type="checkbox"] {
      width: 20px;
      height: 20px;
    }
  }
`;

export const List = styled.ul`
  border-top: 2px solid var(--ierp-text-secondary01);
  margin-bottom: 30px;

  @media (max-width: 760px) {
    order: 1;
    border-top: 0;
  }

  @media (max-width: 374px) {
    margin-bottom: 20px;
  }
`;

export const ListItem = styled.li`
  border-bottom: 1px solid var(--ierp-color-border01);

  input {
    height: 62px;
    line-height: 62px;
    width: 100%;
    padding: 0 12px;
    font-size: 14px;
    border: none;

    &:focus,
    &:active {
      outline: none;
    }

    &::placeholder {
      color: var(--ierp-text-placeholder);
    }
  }

  @media (max-width: 374px) {
    input {
      font-size: 12px;
      line-height: 50px;
      height: 50px;
    }
  }
`;
