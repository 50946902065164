import axios from "axios";
import type { AxiosError } from "axios";

// Axios Config
axios.defaults.baseURL = "/api";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Cache-Control"] = "no-cache, no-store";
axios.defaults.headers.common.Pragma = "no-cache";
axios.defaults.withCredentials = false;

axios.interceptors.response.use(undefined, async (err: AxiosError) => {
  return Promise.reject(err);
});

export default axios;
