import React, { ReactNode, useEffect, useState } from "react";

import useSideTop from "./hooks/useSideTop";

import { ReactComponent as TopIcon } from "./images/icon-sidebar-item01.svg";

import * as S from "./Sidebar.styled";

type Props = {
  path: string;
  children: ReactNode;
};

function Sidebar({ path, children }: Props) {
  const [topPosition, setTopPosition] = useState<number>(0);
  const [changePosition, setChangePostion] = useState<boolean>(false);
  const { top } = useSideTop(path);

  useEffect(() => {
    if (top) setTopPosition(top);
  }, [top]);

  useEffect(() => {
    document.addEventListener("scroll", () =>
      window.scrollY + 300 >= topPosition
        ? setChangePostion(true)
        : setChangePostion(false)
    );
  }, [changePosition, topPosition]);

  const scrollToTop = () => {
    let between = 10; // 이동 간격 시간
    let scroll = window.setInterval(function () {
      let pos = window.pageYOffset;
      let step = 300; // 이동 크기 픽셀

      if (pos > 0) {
        window.scrollTo(0, pos - step);
      } else {
        window.clearInterval(scroll);
      }
    }, between);
  };

  return (
    <S.Wrapper top={topPosition} changePosition={changePosition}>
      {children}
      <S.Item>
        <S.Button onClick={scrollToTop}>
          <TopIcon />
        </S.Button>
      </S.Item>
    </S.Wrapper>
  );
}

export default Sidebar;
