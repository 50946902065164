import React, { useState } from "react";

import { QUICKMENU_PATH } from "constants/routes";

import SubDepthList from "../sub-depth-list/SubDepthList";
import SubDepthItem from "../sub-depth-item/SubDepthItem";

import DownArrow from "./images/icon-header-downarrow.png";

import * as S from "./QuickMenu.styled";

function QuickMenu() {
  const [subDepthShow, setSubDepthShow] = useState<boolean>(false);

  return (
    <S.Wrapper>
      <S.LoginButton>로그인</S.LoginButton>
      <S.QuickMenuWrapper
        onMouseEnter={() => setSubDepthShow(true)}
        onMouseLeave={() => setSubDepthShow(false)}
      >
        <S.QuickMenuButton>
          간편메뉴
          <img src={DownArrow} alt="" />
        </S.QuickMenuButton>
        <SubDepthList depthShow={subDepthShow} topMargin="30px">
          {QUICKMENU_PATH.map((item) => (
            <SubDepthItem
              key={item.label}
              label={item.label}
              path={item.path}
              targetBlank={item.target}
            />
          ))}
        </SubDepthList>
      </S.QuickMenuWrapper>
    </S.Wrapper>
  );
}

export default QuickMenu;
