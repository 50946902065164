import React, { ReactNode } from "react";

import * as S from "./Button.styled";

// button 태그의 기본 props를 사용할 수 있도록 설정
type ButtonHTMLAttributes = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  | "color"
  | "backgroundColor"
  | "border"
  | "fontSize"
  | "lineHeight"
  | "fontWeight"
>;

// width - 150px, 160px, 200px, 220px, 330px, /// 174px, 194px, 100%

// heigth - 46px, 54px, 56px,

// background - primary(#3366FF), white(basic)

// border - none / 1px, 2px / primary(#3366FF), #DDDDDD

// border-radius - 100px,

// font-color - white, primary(#3366FF), #444444

// font-weight - 400, 700

// font-weight

// line-height

export interface Props extends ButtonHTMLAttributes {
  /** 타입 */
  type?: "submit" | "button";

  /** 테마 */
  themeColor?: "basic" | "primary" | "secondary";

  /** 커스텀 폰트색 */
  color?: string;

  /** 커스텀 배경색 */
  backgroundColor?: string;

  /** 커스텀 경계선 */
  border?: string;

  /** 모서리 스타일 */
  edge?: "soft" | "square";

  /** 폰트크기 */
  fontSize?: number;

  /** 줄 높이 */
  lineHeight?: number;

  /** 폰트두께 */
  fontWeight?: "regular" | "medium" | "bold";

  /** width(너비) 100% */
  fluid?: boolean;

  /** width(너비) px */
  widthSize?: number | undefined;

  /** height(높이) px */
  heightSize?: number | undefined;

  /** 아이콘 */
  icon?: ReactNode;

  /** 아이콘 사이즈 */
  iconSize?: number | undefined;

  children: ReactNode;
}

/**
 * 각 타입의 내용 선언
 * @param {"submit" | "button"} type 버튼 타입 default - "submit"
 * @param {"basic" | "primary" | "secondary"} themeColor 버튼 테마 default - "basic"
 * @param {string=} color 커스텀 폰트 색상
 * @param {string=} backgroundColor 커스텀 배경 색상
 * @param {string=} border 커스텀 경계선 - ex) "1px solid white"
 * @param {"soft" | "square"} edge 커스텀 모서리 스타일 default - "soft"
 * @param {number=} fontSize 커스텀 폰트 사이즈 단위 - px
 * @param {number=} lineHeight 커스텀 줄 높이 단위 - px
 * @param {"regular" | "medium" | "bold"} fontWeight 폰트 두께 - 500 | 600 | 700, default 400
 * @param {boolean=} fluid width 100%
 * @param {number=} widthSize width 단위 - px
 * @param {number=} heightSize height 단위 - px
 * @param {ReactNode} icon 아이콘 이미지
 * @param {number=} iconSize 아이콘 사이트 단위 - px
 * @param {ReactNode} children
 */

function Button({ children, ...props }: Props) {
  return (
    <S.Wrapper
      altColor={props.color}
      altBackgroundColor={props.backgroundColor}
      altBorder={props.border}
      altFontSize={props.fontSize}
      altLineHeight={props.lineHeight}
      altFontWeight={props.fontWeight}
      {...props}
    >
      <span>{children}</span>
      {props.icon && props.icon}
    </S.Wrapper>
  );
}

Button.defaultProps = {
  type: "submit",
  themeColor: "basic",
  color: undefined,
  backgroundColor: undefined,
  border: undefined,
  edge: "soft",
  fontSize: undefined,
  lineHeight: undefined,
  fontWeight: undefined,
  fluid: false,
  widthSize: undefined,
  heightSize: undefined,
  icon: undefined,
  iconSize: undefined,
};

export default Button;
