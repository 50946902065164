import React, { ReactNode, useEffect } from "react";
import { Link } from "react-router-dom";

import useIsMobile from "assets/hooks/useIsMobile";

import {
  PATH as ROUTE_PATH,
  SOLUTION_PATH,
  PRODUCT_PATH,
  CHARGE_PATH,
  CUSTOMER_PATH,
  COMPANY_PATH,
} from "constants/routes";

import NavItem, { NavItemProps } from "./components/nav-item";
import QuickMenu from "./components/quick-menu";
import ToggleMenu from "./components/toggle-menu";

import Logo from "assets/images/img-header-logo.png";

import * as S from "./Header.styled";

type Props = {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
};

function Header({ openModal, setOpenModal, setIsShow, children }: Props) {
  const navItems: NavItemProps[] = [
    { label: "솔루션", path: ROUTE_PATH.SOLUTION, subDepth: SOLUTION_PATH },
    { label: "제품소개", path: ROUTE_PATH.PRODUCT, subDepth: PRODUCT_PATH },
    {
      label: "이용요금",
      path: ROUTE_PATH.CHARGE,
      subDepth: CHARGE_PATH,
      disabled: true,
    },
    {
      label: "고객센터",
      path: ROUTE_PATH.CUSTOMER,
      subDepth: CUSTOMER_PATH,
      disabled: true,
    },
    { label: "회사소개", path: ROUTE_PATH.COMPANY, subDepth: COMPANY_PATH },
  ];

  const { isMobile } = useIsMobile();

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    if (!isMobile) {
      document.body.style.overflow = "unset";
      setOpenModal(false);
    }
  });

  const handleModalOpen = () => {
    setOpenModal((modalOpen) => !modalOpen);
    setIsShow(false);
  };

  return (
    <S.Wrapper>
      <Link to="/" className="logo">
        <img src={Logo} alt="Logo" />
      </Link>
      {!isMobile ? (
        <>
          <S.Nav>
            {navItems.map((item) => (
              <NavItem
                key={item.label}
                path={item.path}
                label={item.label}
                subDepth={item.subDepth}
                disabled={item.disabled}
              />
            ))}
          </S.Nav>
          <QuickMenu />
        </>
      ) : (
        <>
          <S.Button onClick={handleModalOpen}>
            <div />
          </S.Button>
          <ToggleMenu
            navItems={navItems}
            modalOpen={openModal}
            setIsShow={setIsShow}
            onOpenModal={handleModalOpen}
          >
            {children}
          </ToggleMenu>
        </>
      )}
    </S.Wrapper>
  );
}

export default Header;
