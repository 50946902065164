import styled from "@emotion/styled";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 100px;
  padding: 0 100px;
  justify-content: space-between;
  align-items: center;
  background: var(--ierp-color-white);
  z-index: 1000;
  transition: all 0.5s;

  .logo {
    display: flex;
  }

  @media (max-width: 1112px) {
    padding: 0 15px;

    .logo {
      img {
        max-width: 80%;
      }
    }
  }

  @media (max-width: 760px) {
    position: sticky;
    top: 0;
    height: 77px;

    .logo {
      img {
        max-width: 70%;
      }
    }
  }

  @media (max-width: 374px) {
    height: 60px;

    .logo {
      img {
        max-width: 65%;
      }
    }
  }
`;

export const Nav = styled.ul`
  display: flex;
  justify-content: space-between;

  @media (max-width: 760px) {
    display: none;
  }
`;

export const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;

  div,
  &:after,
  &:before {
    width: 32px;
    height: 4px;
    background: var(--ierp-color-primary);
    border-radius: 50px;
  }

  div {
    margin: 6px 0;
  }

  &:after,
  &:before {
    content: "";
  }

  @media (max-width: 374px) {
    div,
    &:after,
    &:before {
      width: 26px;
      height: 3px;
      background: var(--ierp-color-primary);
      border-radius: 50px;
    }

    div {
      margin: 5px 0;
    }
  }
`;
