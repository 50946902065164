import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "components/button/Button";

import QuickInquiries from "types/QuickInquiries";
import useQuickInquiries from "hooks/useQuickInquiries";

import DownArrow01 from "../../../../../../assets/images/icon-main-downarrow01.png";

import * as S from "./QuickForm.styled";

type Props = {
  isMobile: boolean;
  isShow: boolean;
  onOpenForm: () => void;
  onOpenModal: () => void;
};

function QuickForm({ isMobile, isShow, onOpenForm, onOpenModal }: Props) {
  const schema = yup
    .object()
    .shape({
      name: yup.string().required("기관명or이름을 입력해주세요."),
      phone: yup.string().required("연락처를 입력해주세요"),
      agree: yup
        .boolean()
        .oneOf([true], "개인정보수집 및 이용동의를 선택해주세요."),
    })
    .required();

  const {
    register,
    handleSubmit,
    setFocus,
    reset,
    formState: { errors },
  } = useForm<QuickInquiries>({ resolver: yupResolver(schema) });
  const mutation = useQuickInquiries();

  useEffect(() => {
    if (errors) {
      if (errors.agree) {
        alert(errors.agree.message);
        return;
      }
      if (errors.name) {
        alert(errors.name.message);
        return;
      }
      if (errors.phone) {
        alert(errors.phone.message);
        return;
      }
    }
  }, [errors]);

  useEffect(() => {
    if (isShow) {
      setFocus("name");
    }
  }, [setFocus, isShow]);

  const onSubmit = (data: QuickInquiries) =>
    mutation.mutate(data, {
      onSuccess: () => {
        alert("간편문의가 등록되었습니다");
        reset();
      },
      onError: (err) => {
        alert("간편문의가 등록에 실패하였습니다");
      },
    });

  return (
    <S.Wrapper onSubmit={handleSubmit(onSubmit)}>
      <S.Dropdown onClick={onOpenForm} isShow={isShow}>
        <span>간편문의 신청</span>
        <img src={DownArrow01} alt="" />
      </S.Dropdown>
      <S.InputBox isShow={isShow}>
        <S.TitleBox className="titleBox">
          <S.Title>간편문의</S.Title>
          <S.CheckBox>
            <S.Label htmlFor="footer_agree">
              <input type="checkbox" {...register("agree")} id="footer_agree" />
            </S.Label>
            <button type="button" onClick={onOpenModal}>
              개인정보 수집 및 이용동의
            </button>
          </S.CheckBox>
        </S.TitleBox>
        <S.List>
          <S.ListItem>
            <input
              type="text"
              {...register("name")}
              placeholder="기관명 or 이름을 입력해주세요"
            />
          </S.ListItem>
          <S.ListItem>
            <input
              type="tel"
              {...register("phone")}
              placeholder="연락처(- 없이) 입력해주세요"
            />
          </S.ListItem>
        </S.List>
        <Button
          themeColor={isMobile ? "basic" : "primary"}
          widthSize={isMobile ? undefined : 220}
          fluid={isMobile ? true : false}
          fontSize={isMobile ? 15 : 16}
          lineHeight={30}
          fontWeight="bold"
        >
          간편문의 신청
        </Button>
      </S.InputBox>
    </S.Wrapper>
  );
}

export default QuickForm;
