import React from "react";
import { QueryClientProvider } from "react-query";
import { ThemeProvider } from "emotion-theming";

import ErrorBoundary from "templates/error-boundary";
import queryClient from "lib/configs/queryClient";
import emotionCache from "lib/configs/emotionCache";
import theme from "theme";
import { CacheProvider } from "@emotion/core";

export interface Props {
  children: React.ReactNode;
}

export default function AppProviders({ children }: Props) {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <CacheProvider value={emotionCache}>{children}</CacheProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}
