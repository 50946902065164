import { useState, useEffect } from "react";

export default function useIsMobile() {
  const [isMobile, setisMobile] = useState<boolean>(window.innerWidth < 760);
  const [widthSize, setWidthSize] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleSize = () => {
      setisMobile(window.innerWidth < 760);
      setWidthSize(window.innerWidth);
    };

    window.addEventListener("resize", handleSize);

    return () => {
      window.removeEventListener("resize", handleSize);
    };
  }, []);

  return { isMobile, widthSize };
}
