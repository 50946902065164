import React, {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Modal from "react-modal";

import { ReactComponent as CloseIcon } from "assets/svg/icon-modal-close.svg";

import * as S from "./CenterModal.styled";

type Props = {
  children: ReactNode;
};

export type handleCenterModal = {
  onOpenModal: () => void;
};

function CenterModal({ children }: Props, ref: React.Ref<unknown>) {
  const [modalOverlay, setModalOverlay] = useState<HTMLDivElement>();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    onOpenModal,
  }));

  const onOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  useEffect(() => {
    modalOverlay?.addEventListener("click", handleCloseModal, true);
  });

  return (
    <Modal
      isOpen={isOpenModal}
      style={S.ModalStyler}
      ariaHideApp={false}
      overlayRef={(node) => setModalOverlay(node)}
    >
      <S.Header>
        <strong>개인정보 수집 및 이용동의안내</strong>
        <S.CloseButton type="button" onClick={handleCloseModal}>
          <CloseIcon />
        </S.CloseButton>
      </S.Header>
      <S.Content>{children}</S.Content>
    </Modal>
  );
}

export default forwardRef(CenterModal);
