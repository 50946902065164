import styled from "@emotion/styled";

type Props = {
  depthShow: boolean;
};

export const Wrapper = styled.li<Props>`
  position: relative;

  &.disabled {
    display: none;
    pointer-events: none;

    a {
      color: var(--ierp-text-secondary04);
    }
  }

  & + & {
    margin-left: 80px;
    transition: margin 0.5s;
  }

  a {
    display: block;
    font-size: 16px;
  }

  a:hover {
    color: var(--ierp-color-primary);
  }

  .navActive {
    color: var(--ierp-color-primary);
  }

  @media (max-width: 1112px) {
    & + & {
      margin-left: 40px;
    }
  }
`;
