import styled from "@emotion/styled";
import { css } from "@emotion/react";

type Props = {
  depthShow?: boolean;
};

export const Wrapper = styled.div<Props>`
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  background: var(--ierp-color-white);
`;

export const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 77px;
  padding: 0 15px;
  margin-bottom: 10px;
  background: var(--ierp-color-primary);

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 35px;
    font-size: 14px;
    line-height: 14px;
    color: var(--ierp-color-white);
    border: 1px solid var(--ierp-color-white);
    border-radius: 100px;
  }

  @media (max-width: 374px) {
    height: 60px;

    a {
      font-size: 12px;
      width: 90px;
      height: 32px;
    }
  }
`;

export const Button = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;

  &:before,
  &:after {
    position: absolute;
    width: 100%;
    height: 4px;
    background: var(--ierp-color-white);
    border-radius: 50px;
    content: "";
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  @media (max-width: 374px) {
    width: 28px;

    &:before,
    &:after {
      height: 3px;
    }
  }
`;

export const Menu = styled.ul`
  border-bottom: 10px solid var(--ierp-color-background01);
  width: 100%;
  padding: 0 15px;
`;

export const MenuItem = styled.li<Props>`
  padding: 20px;
  border-bottom: 1px solid var(--ierp-color-border02);

  &:last-of-type {
    border-bottom: 0;
  }

  /* 미사용 페이지 처리 */
  &.disabled {
    display: none;
    pointer-events: none;
    color: var(--ierp-text-secondary04);

    img {
      display: none;
    }
  }

  @media (max-width: 374px) {
    padding: 16px 20px;
  }
`;

export const MenuHeader = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    transform: ${({ depthShow }) =>
      depthShow ? "rotate(0)" : "rotate(-90deg)"};

    transition: transform 250ms linear;
  }
`;

export const Label = styled.strong`
  font-size: 22px;
  line-height: 32px;

  @media (max-width: 374px) {
    font-size: 18px;
    line-height: 26px;
  }
`;

export const List = styled.ul<Props>`
  opacity: 0;
  transition: all 0.3s ease;

  ${({ depthShow }) =>
    depthShow &&
    css`
      padding-top: 10px;
      opacity: 1;
    `}
`;

export const ListItem = styled.li<Props>`
  height: 0;
  font-size: 15px;
  line-height: 35px;
  transition: all 0.3s ease;

  a {
    display: none;
  }

  ${({ depthShow }) =>
    depthShow &&
    css`
      height: 35px;
      opacity: 1;
      transform: translateY();

      a {
        display: unset;
      }
    `}

  .navActive {
    color: var(--ierp-color-primary);
  }

  @media (max-width: 374px) {
    font-size: 13px;
    line-height: 32px;
  }
`;

export const Footer = styled.div`
  padding: 25px 35px 50px 35px;
`;
