/* eslint comma-dangle: ["error", "always-multiline"] */

const variables = {
  // 메인
  "ierp-color-primary": "#3366FF",

  // 서브
  "ierp-color-secondary01": "#F8FAFF",

  // 경고, 삭제
  "ierp-color-danger": "#ff3b30", // delete
  "ierp-color-warning": "#f9904a",

  // 백그라운드 색상
  "ierp-color-background01": "#f9f9f9",

  // 라인
  "ierp-color-border01": "#DDDDDD",
  "ierp-color-border02": "#EEEEEE",

  // Text
  "ierp-text-primary": "#000000",
  "ierp-text-secondary01": "#444444",
  "ierp-text-secondary02": "#777777",
  "ierp-text-secondary03": "#888888",
  "ierp-text-secondary04": "#999999",
  "ierp-text-placeholder": "#a9a9a9",

  // 기타
  "ierp-color-white": "#fff",
  "ierp-color-black": "#000",
  "ierp-color-shadow": "rgba(0, 0, 0, 0.03)",

  // Transition
  "ierp-transition-duration": "150ms",
};

export default Object.freeze(variables);

export function cssVariables() {
  return Object.keys(variables).reduce((o, key) => {
    // eslint-disable-next-line no-param-reassign
    o[`--${key}`] = variables[key as keyof typeof variables];
    return o;
  }, {} as { [key: string]: string });
}
