import React, { Dispatch, SetStateAction, useRef } from "react";

import useIsMobile from "assets/hooks/useIsMobile";

import CenterModal, { handleCenterModal } from "components/modal/center-modal";

import * as S from "./Footer.styled";
import QuickForm from "./components/quick-form";
import { Link } from "react-router-dom";

type Props = {
  isShow: boolean;
  setIsShow: Dispatch<SetStateAction<boolean>>;
};

function Footer({ isShow, setIsShow }: Props) {
  const termsItems = [
    {
      label: "개인(위치)정보 처리방침",
      link: "https://zinc-kilogram-a8a.notion.site/f44bea263e3c40ac9a77d5058e68a20d",
    },
    {
      label: "개인위치정보사업 이용약관",
      link: "https://zinc-kilogram-a8a.notion.site/9c0f4a5d1960473286598be5ad803db1",
    },
    {
      label: "위치기반서비스 이용약관",
      link: "https://zinc-kilogram-a8a.notion.site/a4faf737d2c044988d9b264315051e65",
    },
  ];

  const modalRef = useRef<handleCenterModal>();
  const { isMobile } = useIsMobile();

  const handleModalOpen = () => {
    modalRef.current?.onOpenModal();
  };

  const handleFormOpen = () => {
    setIsShow((isShow) => !isShow);
  };

  return (
    <>
      <S.Wrapper id="footer">
        <S.InnerBox>
          <S.CustomerInfo>
            {!isMobile && (
              <>
                <S.Title className="serviceTitle">Customer Service</S.Title>
                <S.SubTitle href="tel:1833-2519" className="mainNumber">
                  1833-2519
                </S.SubTitle>
                <S.List>
                  <S.ListItem>
                    <strong>본사/제휴문의</strong>
                    <a className="tel" href="tel:02-546-7946">
                      02-546-7946
                    </a>
                  </S.ListItem>
                  <S.ListItem>
                    <strong>이메일</strong>
                    <a className="email" href="mailto:master@withbrother.co.kt">
                      master@withbrother.co.kr
                    </a>
                  </S.ListItem>
                </S.List>
              </>
            )}
            <S.AddressBox>
              <S.List className="termsItems">
                {termsItems.map((item) => (
                  <S.ListItem className="terms" key={item.label}>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {item.label}
                    </a>
                  </S.ListItem>
                ))}
              </S.List>
              <S.Address>
                <S.Title className="addressTitle">
                  (주)가치브라더{" "}
                  <Link to="/company/map">(본사/지사 확인하기)</Link>
                </S.Title>
                <S.Text>
                  [본사] 서울특별시 구로구 디지털로 31길 19, 404호
                  <br />
                  (구로동, 에이스테크노타워 2차)
                </S.Text>
                <S.Text>
                  [강남 오피스] 서울특별시 강남구 강남대로 302, 6층
                  <br />
                  (역삼동, 동희빌딩)
                </S.Text>
                <S.Text>
                  [부산] 부산광역시 남구 자성로 152, 503-H07호
                  <br />
                  (문현동)
                </S.Text>
                <S.Text>
                  [대전] 대전광역시 중구 대둔산로 419-4, 802-F08호
                  <br />
                  (한밭프라자)
                </S.Text>
              </S.Address>
              <S.Text>
                사업자등록번호 119-86-81107 <br />
                팩스 02-546-7948
              </S.Text>
            </S.AddressBox>
          </S.CustomerInfo>
          <QuickForm
            isMobile={isMobile}
            isShow={isShow}
            onOpenForm={handleFormOpen}
            onOpenModal={handleModalOpen}
          />
        </S.InnerBox>
      </S.Wrapper>
      <CenterModal ref={modalRef}>
        <S.Privacy>
          <ol>
            <li>
              <strong>1.수집 항목</strong>
              <ul>
                <li>- 시설명, 연락처</li>
              </ul>
            </li>
            <li>
              <strong>2.수집 및 이용목적</strong>
              <ul>
                <li>
                  - 고객 문의사항 접수, 문의사항 답변 및 상담을 위한 연락 및
                  통지
                </li>
              </ul>
            </li>
            <li>
              <strong>3.보유 및 이용기간</strong>
              <ul>
                <li>- 상담 접수 및 처리 후 3년(법정 보존기간)</li>
              </ul>
            </li>
          </ol>
          <p>
            *고객께서는 본 개인정보 수집 및 이용을 거부하실 수 있으나, 해당
            동의를 거부하시는 경우 신청서 접수가 불가합니다.
          </p>
        </S.Privacy>
      </CenterModal>
    </>
  );
}

export default Footer;
