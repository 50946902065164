import styled from "@emotion/styled";

export const ModalStyler = {
  overlay: {
    backgroundColor: "rgba(0,0,0,0.3)",
    zIndex: 5000,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    borderRadius: "30px",
    minWidth: "320px",
    border: "none",
    background: "none",
  },
};

export const Header = styled.div`
  background: var(--ierp-color-primary);
  height: 80px;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  strong {
    font-size: 20px;
    color: #fff;
    line-height: 1;
  }

  @media (max-width: 760px) {
    strong {
      font-size: 18px;
    }
  }
`;

export const Content = styled.div`
  background: #fff;
  padding: 20px 30px;
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30px;
  height: 30px;
`;
