import { useMutation } from 'react-query';

import { AxiosError, AxiosResponse } from 'axios';

import client from 'lib/request/client';
import QuickInquiries from 'types/QuickInquiries';

// interface Params {
//   title: string;
//   phone: string;
// }

export default function useQuickInquiries() {
  return useMutation<AxiosResponse<any>, AxiosError, QuickInquiries, any>(
    (data: QuickInquiries) => client.post('/quick-inquiry', data),
  );
}
