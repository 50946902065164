import variables from "./variables";

const colors = {
  // 메인
  primary: variables["ierp-color-primary"],

  // 서브
  secondary01: variables["ierp-color-secondary01"],

  // 라인
  border01: variables["ierp-color-border01"],
  border02: variables["ierp-color-border02"],

  // 백그라운드 색상
  background01: variables["ierp-color-background01"],

  // 텍스트
  tprimary: variables["ierp-text-primary"],
  tsecondary01: variables["ierp-text-secondary01"],
  tsecondary02: variables["ierp-text-secondary02"],
  tsecondary03: variables["ierp-text-secondary03"],
  tsecondary04: variables["ierp-text-secondary04"],
  placeholder: variables["ierp-text-placeholder"],

  // 경고, 삭제
  warningRed: variables["ierp-color-danger"],
  warningOrange: variables["ierp-color-warning"],

  // 기타
  white: variables["ierp-color-white"],
  black: variables["ierp-color-black"],
  shadow: variables["ierp-color-shadow"],
};

export default Object.freeze(colors);
