import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { isIE } from "react-device-detect";

import BaseLayout from "layouts/base-layout";
import styled from "@emotion/styled";
// import ChargeLayout from "layouts/charge-layout";
// import CustomerLayout from "layouts/customer-layout";

const DashboardPage = lazy(() => import("pages/home"));

// 솔루션
const SolutionNurseryPage = lazy(() => import("pages/solution/views/nursery"));
const SolutionKindergartenPage = lazy(
  () => import("pages/solution/views/kindergarten")
);
const SolutionAreaChildrenCenterPage = lazy(
  () => import("pages/solution/views/area-children-center")
);

// 제품소개
const ProductIerpPage = lazy(() => import("pages/product/views/ierp"));
const ProductIogamPage = lazy(() => import("pages/product/views/iogam"));
const ProductThermometerPage = lazy(
  () => import("pages/product/views/thermometer")
);
const ProductOnekizPage = lazy(() => import("pages/product/views/onekiz"));

// 이용요금
// const ChargeEstimatePage = lazy(() => import("pages/charge/views/estimate"));
// const ChargeApplicationPage = lazy(
//   () => import("pages/charge/views/application")
// );

// 고객센터
// const CustomerNoticeListPage = lazy(
//   () => import("pages/customer/views/notice-list")
// );
// const CustomerNoticeDetailPage = lazy(
//   () => import("pages/customer/views/notice-detail")
// );
// const CustomerFaqListPage = lazy(() => import("pages/customer/views/faq-list"));
// const CustomerFaqDetailPage = lazy(
//   () => import("pages/customer/views/faq-detail")
// );
// const CustomerInquiryListPage = lazy(
//   () => import("pages/customer/views/inquiry-list")
// );
// const CustomerInquiryDetailPage = lazy(
//   () => import("pages/customer/views/inquiry-detail")
// );
// const CustomerRemoteSupportPage = lazy(
//   () => import("pages/customer/views/remote-support")
// );

// 회사소개
const CompanyIntroPage = lazy(() => import("pages/company/views/intro"));
const CompanyMapPage = lazy(() => import("pages/company/views/map"));

// 예외처리
const IEPage = lazy(() => import("templates/ie-page"));
const NotFound = lazy(() => import("templates/not-found"));

function AppRoutes() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {isIE ? (
          <Route>
            <Route path="*" element={<IEPage />} />
          </Route>
        ) : (
          <>
            <Route element={<BaseLayout />}>
              <Route index element={<DashboardPage />} />
              <Route path="home" element={<DashboardPage />} />
              <Route path="solution">
                <Route index element={<SolutionNurseryPage />} />
                <Route path="nursery" element={<SolutionNurseryPage />} />
                <Route
                  path="kindergarten"
                  element={<SolutionKindergartenPage />}
                />
                <Route
                  path="area_children_center"
                  element={<SolutionAreaChildrenCenterPage />}
                />
              </Route>
              <Route path="product">
                <Route index element={<ProductIerpPage />} />
                <Route path="ierp" element={<ProductIerpPage />} />
                <Route path="iogam" element={<ProductIogamPage />} />
                <Route
                  path="thermometer"
                  element={<ProductThermometerPage />}
                />
                <Route path="onekiz" element={<ProductOnekizPage />} />
              </Route>
              {/* <Route element={<ChargeLayout />}>
                <Route path="charge">
                  <Route index element={<ChargeEstimatePage />} />
                  <Route path="estimate" element={<ChargeEstimatePage />} />
                  <Route
                    path="application"
                    element={<ChargeApplicationPage />}
                  />
                </Route>
              </Route> */}
              {/* <Route element={<CustomerLayout />}>
                <Route path="customer">
                  <Route index element={<CustomerNoticeListPage />} />
                  <Route path="notice" element={<CustomerNoticeListPage />} />
                  <Route
                    path="notice/detail/:id"
                    element={<CustomerNoticeDetailPage />}
                  />
                  <Route path="faq" element={<CustomerFaqListPage />} />
                  <Route
                    path="faq/detail/:id"
                    element={<CustomerFaqDetailPage />}
                  />
                  <Route path="inquiry" element={<CustomerInquiryListPage />} />
                  <Route
                    path="inquiry/detail/:id"
                    element={<CustomerInquiryDetailPage />}
                  />
                  <Route
                    path="remote_support"
                    element={<CustomerRemoteSupportPage />}
                  />
                </Route>
              </Route> */}
              <Route path="company">
                <Route index element={<CompanyIntroPage />} />
                <Route path="intro" element={<CompanyIntroPage />} />
                <Route path="map" element={<CompanyMapPage />} />
              </Route>
            </Route>
            <Route path="/*" element={<NotFound />} />
          </>
        )}
      </Routes>
    </Suspense>
  );
}

function Loading() {
  const Wrapper = styled.div`
    /** BEGIN CSS **/
    position: fixed;
    background: #fff;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    @keyframes rotate-loading {
      0% {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
      }
    }

    @-moz-keyframes rotate-loading {
      0% {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
      }
    }

    @-webkit-keyframes rotate-loading {
      0% {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
      }
    }

    @-o-keyframes rotate-loading {
      0% {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
      }
    }

    @keyframes rotate-loading {
      0% {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
      }
    }

    @-moz-keyframes rotate-loading {
      0% {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
      }
    }

    @-webkit-keyframes rotate-loading {
      0% {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
      }
    }

    @-o-keyframes rotate-loading {
      0% {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
      }
    }

    @keyframes loading-text-opacity {
      0% {
        opacity: 0;
      }
      20% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    @-moz-keyframes loading-text-opacity {
      0% {
        opacity: 0;
      }
      20% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    @-webkit-keyframes loading-text-opacity {
      0% {
        opacity: 0;
      }
      20% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    @-o-keyframes loading-text-opacity {
      0% {
        opacity: 0;
      }
      20% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    .loading-container,
    .loading {
      height: 100px;
      position: relative;
      width: 100px;
      border-radius: 100%;
    }

    .loading-container {
      margin: 40px auto;
    }

    .loading {
      border: 2px solid transparent;
      border-color: transparent var(--ierp-color-primary) transparent
        var(--ierp-color-primary);
      -moz-animation: rotate-loading 1.5s linear 0s infinite normal;
      -moz-transform-origin: 50% 50%;
      -o-animation: rotate-loading 1.5s linear 0s infinite normal;
      -o-transform-origin: 50% 50%;
      -webkit-animation: rotate-loading 1.5s linear 0s infinite normal;
      -webkit-transform-origin: 50% 50%;
      animation: rotate-loading 1.5s linear 0s infinite normal;
      transform-origin: 50% 50%;
    }
    .loading-container .loading {
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }

    #loading-text {
      -moz-animation: loading-text-opacity 0.5s linear 0s infinite normal;
      -o-animation: loading-text-opacity 0.5s linear 0s infinite normal;
      -webkit-animation: loading-text-opacity 0.5s linear 0s infinite normal;
      animation: loading-text-opacity 0.5s linear 0s infinite normal;
      color: var(--ierp-text-secondary01);
      font-family: "Helvetica Neue, " Helvetica ", " "arial";
      font-size: 10px;
      font-weight: bold;
      margin-top: 45px;
      opacity: 1;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      top: 0;
      width: 100px;
    }
  `;

  return (
    <Wrapper>
      <div className="loading-container">
        <div className="loading"></div>
        <div id="loading-text">loading</div>
      </div>
    </Wrapper>
  );
}

export default AppRoutes;
