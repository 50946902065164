import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { QUICKMENU_PATH } from "constants/routes";

import useIsMobile from "assets/hooks/useIsMobile";

import QuickButtonList, {
  QuickButtonItemProps,
} from "./components/quick-button-list";

import Header from "./components/header";
import SideBar from "./components/sidebar";
import Footer from "./components/footer";

import { ReactComponent as Icon01 } from "../../assets/svg/icon-quickbutton-item01.svg";
import { ReactComponent as Icon02 } from "../../assets/svg/icon-quickbutton-item02.svg";
import { ReactComponent as Icon03 } from "../../assets/svg/icon-quickbutton-item03.svg";
import { ReactComponent as Icon04 } from "../../assets/svg/icon-quickbutton-item04.svg";
import { ReactComponent as MobileIcon01 } from "../../assets/svg/icon-quickbutton-mobileitem01.svg";
import { ReactComponent as MobileIcon02 } from "../../assets/svg/icon-quickbutton-mobileitem02.svg";
import { ReactComponent as MobileIcon03 } from "../../assets/svg/icon-quickbutton-mobileitem03.svg";
import { ReactComponent as MobileIcon04 } from "../../assets/svg/icon-quickbutton-mobileitem04.svg";

import * as S from "./BaseLayout.styled";

function BaseLayout() {
  const { pathname } = useLocation();
  const { isMobile } = useIsMobile();
  const [quickMenuItems, setQuickMenuItems] = useState<QuickButtonItemProps[]>(
    []
  );
  const [isShow, setIsShow] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const icons = useMemo(
    () =>
      isMobile
        ? [
            <MobileIcon01 />,
            <MobileIcon02 />,
            <MobileIcon03 />,
            <MobileIcon04 />,
          ]
        : [<Icon01 />, <Icon02 />, <Icon03 />, <Icon04 />],
    [isMobile]
  );

  useEffect(() => {
    const newQuickMenuItems: QuickButtonItemProps[] = [];
    QUICKMENU_PATH.map((item, idx) => {
      if (item.key === "estimate") {
        return null;
      } else {
        return newQuickMenuItems.push({
          label: item.quickLabel,
          path: item.path,
          icon: icons[idx],
        });
      }
    });
    setQuickMenuItems(newQuickMenuItems);
  }, [icons]);

  const scrollToBottomWeb = () => {
    let between = 10; // 이동 간격 시간
    let scroll = window.setInterval(function () {
      let pos = window.scrollY + window.innerHeight;
      let step = 100; // 이동 크기 픽셀
      if (pos < document.body.scrollHeight - 10) {
        window.scrollTo(0, pos + step);
      } else {
        window.clearInterval(scroll);
      }
    }, between);
  };

  const scrollToBottomMobile = () => {
    setOpenModal(false);
    window.scrollTo(0, document.body.offsetHeight);
    setIsShow(true);
  };

  return (
    <>
      <Header
        setIsShow={setIsShow}
        openModal={openModal}
        setOpenModal={setOpenModal}
      >
        <QuickButtonList
          items={quickMenuItems}
          onClick={scrollToBottomMobile}
        />
      </Header>
      <SideBar path={pathname}>
        <QuickButtonList items={quickMenuItems} onClick={scrollToBottomWeb} />
      </SideBar>
      <S.ContentWrapper>
        <Outlet />
      </S.ContentWrapper>
      <Footer isShow={isShow} setIsShow={setIsShow} />
    </>
  );
}

export default BaseLayout;
