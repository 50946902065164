import { Plugin as StylisPlugin } from "@emotion/stylis";

export interface CssVariables {
  [variable: string]: string;
}

export interface CssVariableStylisPluginOptions {
  variables: CssVariables;
  prefix?: string;
  fallback?: boolean;
}

export default function cssVariableStylisPlugin({
  variables,
  fallback,
  prefix = "--",
}: CssVariableStylisPluginOptions): StylisPlugin {
  const regex = new RegExp(`var\\(${prefix}([A-Za-z0-9-]+)\\)`);

  return (ctx, str: string) => {
    if (ctx === 1 && regex.test(str)) {
      const replaced = str.replace(
        regex,
        (value, cssVar) => variables[cssVar] || value
      );
      if (str !== replaced) {
        return fallback ? [replaced, str].join(";") : replaced;
      }
    }
    return undefined;
  };
}
