import styled from "@emotion/styled";

type Props = {
  depthShow: boolean;
  topMargin?: string;
};

export const Wrapper = styled.div<Props>`
  position: absolute;
  left: 50%;
  top: 20px;
  min-width: 150px;
  padding-top: 25px;
  transform: translateX(-50%);
  ${({ depthShow }) => (depthShow ? "display:block;" : "display:none;")}
  ${({ topMargin }) => topMargin && `top:${topMargin}`}
`;

export const SubDepthList = styled.ul`
  padding: 20px;
  border: 1px solid var(--ierp-color-primary);
  background: var(--ierp-color-white);
  border-radius: 20px;
`;
