import styled from "@emotion/styled";

export const Wrapper = styled.ul`
  text-align: center;

  @media (max-width: 760px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

export const Item = styled.li`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  border-radius: 15px;
  border: 1px solid rgba(51, 102, 255, 0.2);
  background-color: var(--ierp-color-white);

  @media (hover: hover) {
    &:hover {
      background: var(--ierp-color-primary);

      svg {
        filter: brightness(0) invert();
      }
    }
  }

  @media (max-width: 1112px) {
    height: 40px;
    width: 40px;
  }

  @media (max-width: 760px) {
    width: 64px;
    height: 64px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  @media (max-width: 374px) {
    width: 50px;
    height: 50px;
  }
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  border: 1px solid rgba(51, 102, 255, 0.2);
  background: var(--ierp-color-white);
  border-radius: 15px;

  @media (hover: hover) {
    &:hover {
      background: var(--ierp-color-primary);

      svg {
        filter: brightness(0) invert();
      }
    }
  }

  @media (max-width: 1112px) {
    height: 40px;
    width: 40px;
  }

  @media (max-width: 760px) {
    width: 64px;
    height: 64px;
    border: 1px solid rgba(51, 102, 255, 0.2);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  @media (max-width: 374px) {
    width: 50px;
    height: 50px;
  }
`;

export const Label = styled.span`
  font-size: 13px;
  line-height: 24px;
  color: var(--ierp-color-primary);

  @media (max-width: 1112px) {
    font-size: 11px;
  }

  @media (max-width: 760px) {
    font-size: 15px;
    line-height: 24px;
  }
`;
