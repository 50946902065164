import React from "react";
import { NavLink } from "react-router-dom";

import * as S from "./SubDepthItem.styled";

type Props = {
  label: string;
  path: string;
  targetBlank?: boolean;
};

function SubDepthItem({ label, path, targetBlank }: Props) {
  const scrollToBottom = () => {
    let between = 10; // 이동 간격 시간
    let scroll = window.setInterval(function () {
      let pos = window.scrollY + window.innerHeight;
      let step = 100; // 이동 크기 픽셀
      if (pos < document.body.scrollHeight - 10) {
        window.scrollTo(0, pos + step);
      } else {
        window.clearInterval(scroll);
      }
    }, between);
  };

  return (
    <S.Wrapper>
      {label === "간편문의" ? (
        <S.Button onClick={scrollToBottom}>{label}</S.Button>
      ) : targetBlank ? (
        <a href={path} target="_blank" rel="noreferrer noopener">
          {label}
        </a>
      ) : (
        <NavLink
          to={path}
          className={({ isActive }) => (isActive ? "subNavActive" : undefined)}
        >
          {label}
        </NavLink>
      )}
    </S.Wrapper>
  );
}

export default SubDepthItem;
