import React from "react";

import WapperErrorBoundary, {
  WapperErrorBoundaryProps,
  WapperErrorHandlerProps,
} from "templates/wapper-error-boundary";

type Props = WapperErrorBoundaryProps;

function ErrorBoundary({ children, onError }: Props) {
  return (
    <WapperErrorBoundary fallBack={ErrorHandler} onError={onError}>
      {children}
    </WapperErrorBoundary>
  );
}

function ErrorHandler({ error }: WapperErrorHandlerProps) {
  const isDev = process.env.NODE_ENV === "development";
  return <div>{isDev ? <>{error}</> : <>{error}</>}</div>;
}

export default ErrorBoundary;
