import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LoginButton = styled.a`
  margin-right: 24px;
  font-size: 15px;
  line-height: 24px;

  /* 로그인 버튼 미사용 */
  display: none;
`;

export const QuickMenuWrapper = styled.div`
  position: relative;
  cursor: default;
`;

export const QuickMenuButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 120px;
  height: 46px;
  background: var(--ierp-color-primary);
  color: var(--ierp-color-white);
  align-items: center;
  padding: 0 13px 0 20px;
  border-radius: 15px;
`;
