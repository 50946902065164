import { css } from "@emotion/core";
import styled from "@emotion/styled";

import { Props } from "./Button";

// button 태그 props와 명칭이 겹치는 props 구분을 위해 alt를 붙여 타입 선언
interface StyleProps
  extends Omit<
    Props,
    | "color"
    | "backgroundColor"
    | "border"
    | "fontSize"
    | "lineHeight"
    | "fontWeight"
  > {
  altColor: Props["color"];
  altBackgroundColor: Props["backgroundColor"];
  altBorder: Props["border"];
  altFontSize: Props["fontSize"];
  altLineHeight: Props["lineHeight"];
  altFontWeight: Props["fontWeight"];
}

// fluid 상태에 따라 width(너비) 값 적용하여 변수에 담기
const buttonWidth = (widthSize: Props["widthSize"], fluid: Props["fluid"]) => {
  if (widthSize) {
    return `${widthSize}px`;
  }
  return fluid ? "100%" : "auto";
};

export const Wrapper = styled.button<StyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  ${({ widthSize, heightSize, fluid, iconSize }) => css`
    height: ${heightSize ? heightSize : 56}px;
    width: ${buttonWidth(widthSize, fluid)};

    @media (max-width: 374px) {
      height: ${(heightSize ? heightSize : 56) * 0.8}px;

      svg {
        height: ${iconSize && iconSize}px;
      }
    }
  `}

  // 테마 타입에 따른 기본설정
  ${({ themeColor }) =>
    themeColor === "basic" &&
    css`
      color: var(--ierp-color-primary);
      border: 1px solid var(--ierp-color-primary);
    `}

  ${({ themeColor }) =>
    themeColor === "primary" &&
    css`
      background-color: var(--ierp-color-primary);
      color: var(--ierp-color-white);
      border: none;
    `}

    ${({ themeColor }) =>
    themeColor === "secondary" &&
    css`
      color: var(--ierp-text-secondary03);
      border: 1px solid var(--ierp-color-border01);
    `}

  ${({
    altColor,
    altBackgroundColor,
    altBorder,
    altFontSize,
    altLineHeight,
  }) => css`
    color: ${altColor};
    background-color: ${altBackgroundColor};
    border: ${altBorder};
    font-size: ${altFontSize}px;
    line-height: ${altLineHeight}px;

    @media (max-width: 374px) {
      font-size: ${altFontSize && altFontSize * 0.8}px;
      line-height: ${altFontSize && altFontSize * 0.8}px;
    }
  `}

  ${({ altFontWeight }) =>
    altFontWeight === "regular" &&
    css`
      font-weight: 500;
    `}

  ${({ altFontWeight }) =>
    altFontWeight === "medium" &&
    css`
      font-weight: 600;
    `}

  ${({ altFontWeight }) =>
    altFontWeight === "bold" &&
    css`
      font-weight: bold;
    `}

  ${({ edge }) =>
    edge === "soft" &&
    css`
      border-radius: 100px;
    `}

  ${({ edge }) =>
    edge === "square" &&
    css`
      border-radius: 0;
    `}

  ${({ icon }) =>
    icon &&
    css`
      justify-content: space-around;
    `}
`;
