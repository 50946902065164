import React, { Component, ComponentType, ErrorInfo, ReactNode } from "react";
import { AxiosError } from "axios";

type Props = {
  children: ReactNode;
  fallBack?: ComponentType<WapperErrorHandlerProps>;
  onError?: (error?: Error | AxiosError) => void;
};

type State = {
  hasError: boolean;
  error?: Error | AxiosError;
};

type WapperErrorHandlerProps = {
  error?: Error | AxiosError;
};

class WapperErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  public static getDerivedStateFromError(error: Error | AxiosError): State {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    const { children, fallBack: FallBack, onError } = this.props;
    const { hasError, error } = this.state;

    if (hasError) {
      if (onError) onError(error);
      if (FallBack) return <FallBack error={error} />;
      return <h1>{`${error}`}</h1>;
    }

    return children;
  }
}

export type { Props as WapperErrorBoundaryProps, WapperErrorHandlerProps };

export default WapperErrorBoundary;
