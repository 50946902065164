import styled from "@emotion/styled";

export const Wrapper = styled.li`
  display: flex;
  justify-content: center;

  a {
    display: block;
    font-size: 14px;
    text-align: center;
    line-height: 34px;
  }

  a:hover {
    color: var(--ierp-color-primary);
    text-decoration: underline;
  }

  .subNavActive {
    color: var(--ierp-color-primary);
    text-decoration: underline;
  }
`;

export const Button = styled.button`
  font-size: 14px;
  text-align: center;
  line-height: 34px;

  &:hover {
    color: var(--ierp-color-primary);
    text-decoration: underline;
  }
`;
