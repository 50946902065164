import React, { ReactNode } from "react";

import * as S from "./QuickButtonList.styled";

type QuickButtonProps = {
  label?: string;
  icon?: ReactNode;
  path?: string;
};

type Props = {
  items: QuickButtonProps[];
  onClick: () => void;
};

function QuickButtonList({ items, onClick }: Props) {
  return (
    <S.Wrapper>
      {items.map((item) => (
        <S.Item key={item.label ? item.label : "scrollTop"}>
          {item.label && (
            <>
              {item.label === "간편문의" ? (
                <S.Button onClick={onClick} className="scrollBottom">
                  {item.icon}
                </S.Button>
              ) : (
                <S.Link href={item.path} target="_blank">
                  {item.icon}
                </S.Link>
              )}
              <S.Label>{item.label}</S.Label>
            </>
          )}
        </S.Item>
      ))}
    </S.Wrapper>
  );
}

export type { QuickButtonProps as QuickButtonItemProps };
export default QuickButtonList;
