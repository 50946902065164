import React, { ReactNode } from "react";

import * as S from "./SubDepthList.styled";

type Props = {
  children: ReactNode;
  depthShow: boolean;
  topMargin?: string;
};

function SubDepthList({ children, depthShow, topMargin }: Props) {
  return (
    <S.Wrapper depthShow={depthShow} topMargin={topMargin}>
      <S.SubDepthList>{children}</S.SubDepthList>
    </S.Wrapper>
  );
}

export default SubDepthList;
