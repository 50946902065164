import colors from "./colors";

export const theme: any = Object.freeze({
  name: "kn",
  colors,
  borderRadius: 8,
  transitionDuration: "150ms",
});

export default theme;
