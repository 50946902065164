import styled from "@emotion/styled";
import { css } from "@emotion/react";

type Props = {
  top: number;
  changePosition: boolean;
};

export const Wrapper = styled.div<Props>`
  ${({ changePosition, top }) =>
    changePosition
      ? css`
          position: fixed;
          top: 300px;
        `
      : css`
          position: absolute;
          top: ${top}px;
        `}
  right: 50px;
  z-index: 2;
  transition: right 0.5s;

  @media (max-width: 1112px) {
    right: 20px;
  }

  @media (max-width: 760px) {
    display: none;
  }
`;

export const Item = styled.li`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  border-radius: 15px;
  border: 1px solid var(--ierp-color-primary);
  background-color: var(--ierp-color-primary);
  padding-top: 5px;

  &.scrollBottom {
    border: 1px solid var(--ierp-color-primary);
    background: var(--ierp-color-white);

    @media (hover: hover) {
      &:hover {
        background: var(--ierp-color-primary);

        svg {
          filter: brightness(0) invert();
        }
      }
    }
  }

  @media (max-width: 1112px) {
    height: 40px;
    width: 40px;
  }
`;
