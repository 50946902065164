import createCache from "@emotion/cache";
import cssVariablePlugin from "../stylisPlugins/cssVariable";
import cssVariables from "../../theme/variables";

export const emotionCache = createCache({
  stylisPlugins: [
    cssVariablePlugin({
      variables: cssVariables,
      fallback: true,
    }),
  ],
});

export default emotionCache;
