import { useEffect, useState } from "react";

export default function useSideTop(path: string) {
  const [top, setTop] = useState<number>();

  useEffect(() => {
    const element = document.body.querySelector(".sideTop");
    if (element) {
      setTop(element?.getBoundingClientRect().bottom + 145);
    } else {
      setTop(400);
    }
  }, [path]);
  return {
    top,
  };
}
