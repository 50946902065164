import styled from "@emotion/styled";

export const Wrapper = styled.div`
  padding: 60px 0 70px;

  @media (max-width: 1112px) {
    padding: 60px 100px 70px 100px;
  }

  @media (max-width: 760px) {
    padding: 0 0 50px;
  }
`;

export const InnerBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 1200px;
  transition: all 0.5s;

  @media (max-width: 1112px) {
    width: 100%;
  }

  @media (max-width: 760px) {
    flex-direction: column-reverse;
    row-gap: 30px;
    text-align: center;
  }

  @media (max-width: 374px) {
    row-gap: 20px;
  }
`;

export const CustomerInfo = styled.div`
  @media (max-width: 1112px) {
    width: 100%;
  }
`;

export const Title = styled.strong`
  display: block;
  font-size: 14px;
  line-height: 30px;

  &.serviceTitle {
    margin-bottom: 15px;
  }

  &.addressTitle {
    font-size: 16px;
    margin-top: 5px;
    color: var(--ierp-text-secondary03);
  }

  @media (max-width: 1112px) {
    &.serviceTitle {
      font-size: 13px;
      margin-bottom: 10px;
    }
  }
`;

export const SubTitle = styled.a`
  display: block;
  font-weight: bold;
  font-size: 36px;
  line-height: 30px;
  margin-bottom: 15px;

  @media (max-width: 1112px) {
    font-size: 32px;
    margin-bottom: 10px;
  }
`;

export const List = styled.ul`
  margin-bottom: 30px;

  &.termsItems {
    display: flex;
    margin-bottom: 0;
  }

  @media (max-width: 1112px) {
    margin-bottom: 20px;

    &.termsItems {
      flex-direction: column;
    }
  }

  @media (max-width: 760px) {
    &.termsItems {
      justify-content: center;
    }
  }
`;

export const ListItem = styled.li`
  strong,
  .tel,
  .email {
    line-height: 28px;
    font-size: 15px;
  }

  strong {
    font-weight: 600;
    margin-right: 10px;
  }

  &.terms {
    margin-right: 16px;

    a {
      font-size: 14px;
      line-height: 30px;
      font-weight: 500;
    }
  }

  @media (max-width: 1112px) {
    .tel,
    .email {
      font-size: 15px;
      line-height: 28px;
    }

    &.terms {
      a {
        font-size: 14px;
        line-height: 26px;
      }
    }
  }

  @media (max-width: 760px) {
    &.terms {
      margin-right: 0;
    }
  }

  @media (max-width: 374px) {
    &.terms {
      a {
        line-height: 24px;
        font-size: 13px;
      }
    }
  }
`;

export const AddressBox = styled.div`
  button {
    display: inline-block;
    font-size: 14px;
    line-height: 30px;
    font-weight: 500;
    color: var(--ierp-text-black);
  }
`;

export const Address = styled.address`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: var(--ierp-text-secondary03);

  br {
    display: none;
  }

  @media (max-width: 1112px) {
    font-size: 13px;
    line-height: 22px;
    color: var(--ierp-text-secondary03);

    br {
      display: unset;
    }
  }

  @media (max-width: 760px) {
    font-size: 12px;
  }
`;

export const Privacy = styled.div`
  ol > li {
    strong {
      font-size: 16px;
      margin-bottom: 5px;
      font-weight: 500;
      color: var(--ierp-text-primary);
    }
    font-size: 14px;
    line-height: 28px;
    margin-top: 20px;
    color: var(--ierp-text-secondary01);
  }

  ol > li:first-of-type {
    margin-top: 0px;
  }

  p {
    font-size: 14px;
    color: var(--ierp-text-secondary04);
    margin-top: 30px;
  }
`;
