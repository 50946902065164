import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Img from "assets/favicon/favicon.gif";
import Ico from "assets/favicon/favicon.gif";
// import linkImg from "";

export default function AppHead() {
  return (
    <HelmetProvider>
      <Helmet>
        {/* 홈 대시보드 타이틀 */}
        <title>아이알피 & 걸음마 아이오감</title>

        {/* favicon */}
        <link rel="apple-touch-icon" sizes="180x180" href={Img} />
        <link rel="apple-touch-icon" sizes="180x180" href={Img} />
        <link rel="icon" type="image/png" sizes="192x192" href={Img} />
        <link rel="icon" type="image/png" sizes="32x32" href={Img} />
        <link rel="icon" type="image/png" sizes="16x16" href={Img} />
        <link key="favicon" rel="shortcut icon" href={Ico} />
        <link rel="icon" href={Ico} />
      </Helmet>
    </HelmetProvider>
  );
}
