import React, { ReactNode, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { serviceLink } from "constants/serviceLink";

import { NavItemProps } from "../nav-item";

import DownArrow01 from "./images/icon-toggle-downarrow01.png";

import * as S from "./ToggleMenu.styled";

type Props = {
  navItems: NavItemProps[];
  modalOpen: boolean;
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  onOpenModal: () => void;
  children: ReactNode;
};

function ToggleMenu({ modalOpen, onOpenModal, navItems, children }: Props) {
  const navItemsLength = navItems.length;

  const [subDepthShow, setSubDepthShow] = useState<boolean[]>([]);

  useEffect(() => {
    const subDepthItem = [];

    for (let i = 0; i < navItemsLength; i++) {
      subDepthItem.push(false);
    }

    setSubDepthShow(subDepthItem);
  }, [navItemsLength]);

  const handleDepthShow = (idx: number) => {
    let newSubDepthShow = [...subDepthShow];
    newSubDepthShow[idx] = !newSubDepthShow[idx];
    setSubDepthShow(newSubDepthShow);
  };

  return (
    <>
      {modalOpen && (
        <S.Wrapper>
          <S.Header>
            <a
              href={`${serviceLink}/pay`}
              target="_blank"
              rel="noreferrer noopener"
            >
              결제하기
            </a>
            <S.Button onClick={onOpenModal}>
              <div />
            </S.Button>
          </S.Header>
          <S.Menu>
            {navItems.map((item, idx) => (
              <S.MenuItem
                className={item.disabled ? "disabled" : undefined}
                key={`${item.label + item.path}`}
              >
                <S.MenuHeader
                  depthShow={subDepthShow[idx]}
                  onClick={() => handleDepthShow(idx)}
                >
                  <S.Label>{item.label}</S.Label>
                  <img src={DownArrow01} alt="" />
                </S.MenuHeader>
                <S.List depthShow={subDepthShow[idx]}>
                  {item.subDepth?.map((subItem) => (
                    <S.ListItem
                      depthShow={subDepthShow[idx]}
                      key={`${subItem.path + subItem.label}`}
                      onClick={onOpenModal}
                    >
                      <NavLink
                        to={subItem.path}
                        className={({ isActive }) =>
                          isActive ? "navActive" : undefined
                        }
                      >
                        {subItem.label}
                      </NavLink>
                    </S.ListItem>
                  ))}
                </S.List>
              </S.MenuItem>
            ))}
          </S.Menu>
          <S.Footer>{children}</S.Footer>
        </S.Wrapper>
      )}
    </>
  );
}

export default ToggleMenu;
